// src/components/About.js
import React, { useState, useEffect } from "react";
import useLoadResources from "../../hooks/useLoadResources";

import Preloader from "../../components/Preloader";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ChooseUs from "../../components/ChooseUs";
import Clients from "../../components/Clients";
import Features from "../../components/Features";
import FunFact from "../../components/FunFact";
import FAQ from "../../components/FAQ";
import CTA from "../../components/CTA";
import Footer from "../../components/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChatBot from "../../components/chatbot/ChatBot";

import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {

  const [settings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  });

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem("myModalLastShown");
    const thirtyMinutes = 30 * 60 * 1000;
    const now = Date.now();

    // If it's been more than 30 minutes or never shown, display modal
    if (!lastShown || now - lastShown > thirtyMinutes) {
      setShowDisclaimer(true);
    }
  }, []);

  const handleClose = () => {
    setShowDisclaimer(false);

    const now = Date.now();
    localStorage.setItem("myModalLastShown", now);
  };

  const resources = [
    { type: "script", src: "../../assets/js/jquery.js" },
    { type: "script", src: "../../assets/js/bootstrap.min.js" },
    { type: "script", src: "../../assets/js/owl.js" },
    { type: "script", src: "../../assets/js/wow.js" },
    { type: "script", src: "../../assets/js/jquery.fancybox.js" },
    { type: "script", src: "../../assets/js/appear.js" },
    { type: "script", src: "../../assets/js/isotope.js" },
    { type: "script", src: "../../assets/js/jquery.nice-select.min.js" },
    { type: "script", src: "../../assets/js/aos.js" },
    { type: "script", src: "../../assets/js/script.js" },
    { type: "link", src: "../../assets/css/font-awesome-all.css" },
    { type: "link", src: "../../assets/css/flaticon.css" },
    { type: "link", src: "../../assets/css/owl.css" },
    { type: "link", src: "../../assets/css/bootstrap.css" },
    { type: "link", src: "../../assets/css/jquery.fancybox.min.css" },
    { type: "link", src: "../../assets/css/animate.css" },
    { type: "link", src: "../../assets/css/aos.css" },
    { type: "link", src: "../../assets/css/nice-select.css" },
    { type: "link", src: "../../assets/css/elpath.css" },
    { type: "link", src: "../../assets/css/color.css" },
    { type: "link", src: "../../assets/css/style.css" },
    { type: "link", src: "../../assets/css/module-css/page-title.css" },
    { type: "link", src: "../../assets/css/module-css/feature.css" },
    { type: "link", src: "../../assets/css/module-css/clients.css" },
    { type: "link", src: "../../assets/css/module-css/chooseus.css" },
    { type: "link", src: "../../assets/css/module-css/cta.css" },
    { type: "link", src: "../../assets/css/module-css/testimonial.css" },
    { type: "link", src: "../../assets/css/module-css/faq.css" },
    { type: "link", src: "../../assets/css/responsive.css" },
    { type: "link", src: "../../assets/css/chatbot.scss" },
  ];

  useLoadResources(resources);

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showPreloader && <Preloader />}
      <Header />
      <Banner />
      <ChooseUs />
      <Clients />
      <Features />
      <FunFact />

      <section class="feature-style-three pb_150">
        <div class="auto-container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
              <div class="content_block_one">
                <div class="content-box mr_50">
                  <div class="sec-title mb_50">
                    <span class="sub-title">Advanced Platform</span>
                    <h2>Access our advanced AI platform</h2>
                  </div>
                  <div class="inner-box">
                    <div class="single-item">
                      <h3>Full-Spectrum AI Capabilities</h3>
                      <p>Generate stunning visuals, interact with AI chat, explore community generations, and more all within our online platform.</p>
                    </div>
                    <div class="single-item">
                      <h3>Mobile Bots</h3>
                      <p>If you prefer a mobile interface, we also offer Telegram and Discord bots which can be added to your own groups and channels!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 image-column">
              <div class="image_block_two">
                <div class="image-box pr_45 pt_40 pl_100 ml_40 pb_65">
                  <figure class="image image-1 platform-image-front" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                    <img src="assets/images/resource/dashboard-5.jpg" alt="" />
                  </figure>
                  <figure class="image image-2 platform-image-back" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700">
                    <img src="assets/images/resource/dashboard-6.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="clients-section centred pb_150" style={{ background: "#fff" }}>
        <div class="auto-container">
          <div class="sec-title mb_60">
            <h2>Press release published in</h2>
          </div>

          <Slider {...settings}>
            <img src="assets/images/clients/publication-1.png" alt="" />
            <img src="assets/images/clients/publication-2.png" alt="" />
            <img src="assets/images/clients/publication-4.1.png" alt="" />
            <img src="assets/images/clients/publication-4.png" alt="" />
            <img src="assets/images/clients/publication-5.png" alt="" />
          </Slider>
        </div>
      </section>
      <FAQ />
      <CTA />
      <Footer />

      <ChatBot />

      <Modal show={showDisclaimer} onHide={handleClose} backdrop="static" keyboard={false} style={{ height: "auto" }}>
        <Modal.Header>
          <Modal.Title>Legal Disclaimer</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>Important Notice: Restriction on Certain Persons</h3>
          <p>
            Nothing presented on this website or through our apps should be interpreted as an offer or solicitation to buy or sell any securities or tokens in any jurisdiction where such actions would be prohibited or unenforceable under applicable laws. Users must ensure compliance with local laws
            and regulations before engaging with MIRX tokens. MIRX tokens and Mirada AI services are not available to persons located in, residents, or citizens of the United States.
          </p>

          <h3>No Offer or Solicitation</h3>
          <p>
            The MIRX token and any related services or products provided by Mirada AI are not available to individuals or entities that are subject to restrictions under applicable laws and regulations. This includes, but is not limited to, individuals located in jurisdictions where such offerings
            would be unlawful. No actions taken on this website or platform constitute an offer, solicitation, or recommendation of any kind.
          </p>

          <h3>Compliance with Local Laws</h3>
          <p>
            It is your responsibility to ensure that participating in the purchase or sale of MIRX tokens complies with the legal and regulatory frameworks of your own jurisdiction. Mirada AI expressly disclaims responsibility for any violations of local regulations by users. Mirada AI does not
            intend to offer MIRX tokens in jurisdictions where doing so would be illegal.
          </p>

          <h3>No Legal or Financial Advice</h3>
          <p>
            All information provided through this website and our apps is intended solely for informational purposes and does not constitute legal, financial, or investment advice. Mirada AI does not provide any advice or recommendations regarding token trading, purchasing, or selling. Always
            consult your own professional advisors before making decisions regarding MIRX tokens or similar activities.
          </p>

          <h3>Disclaimer of Liability, Token Nature, and Liquidity</h3>
          <p>
            Mirada AI does not accept any responsibility for losses incurred by individuals or entities as a result of their engagement with MIRX tokens. The MIRX token is a utility token used to access Mirada AI products and services and does not confer any shareholder rights, equity, profit
            participation from Mirada AI revenues, or ownership interest in Mirada AI. By participating in transactions involving MIRX tokens, users acknowledge and accept that they are solely responsible for their trading decisions and any associated risks, including but not limited to financial
            losses. Mirada AI does not guarantee the liquidity of the MIRX token or its availability on exchanges. Users understand and accept that
            the value and liquidity of MIRX tokens may fluctuate significantly.
          </p>

          <h3>Risk Disclosure</h3>
          <p>
            Investing in digital assets and cryptocurrencies involves significant risk, including the potential for a total loss of capital. Users are advised to carefully consider the inherent risks before engaging with MIRX tokens, including the possibility of limited liquidity or market
            availability. Consider your financial situation and risk tolerance carefully before engaging with MIRX tokens.
          </p>

          <h3>Updates and Amendments</h3>
          <p>Mirada AI reserves the right to revise this disclaimer at any time. You are responsible for reviewing this disclaimer regularly to stay informed of any changes.</p>
        </Modal.Body>

        <Modal.Footer style={{ paddingBottom: "65px" }}>
          <Button variant="primary" onClick={handleClose} style={{ backgroundColor: "#ff1d01d9", borderColor: "#ff1d01d9" }}>
            I Agree
          </Button>
        </Modal.Footer>
      </Modal>

      <div class="scroll-to-top">
        <div>
          <div class="scroll-top-inner">
            <div class="scroll-bar">
              <div class="bar-inner"></div>
            </div>
            <div class="scroll-bar-text">Go To Top</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
