// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
// import About from "./pages/about/About";
// import Token from "./pages/token/Token";
// import Status from "./pages/status/Status";
// import Sentinel from "./pages/sentinel/Sentinel";
// import Research from "./pages/research/Research";
// import Products from "./pages/products/Products";
// import MediaKit from "./pages/mediakit/MediaKit";
// import Roadmap from "./pages/roadmap/Roadmap";
// import Careers from "./pages/careers/Careers";
// import CareersList from "./pages/careers/list/CareersList";
// import Internship from "./pages/careers/internship/Internship";
// import DevFrontEnd from "./pages/careers/front-end-dev/FrontEndDev";
// import Contact from "./pages/contact/Contact";
// import Dashboard from "./pages/dashboard/Dashboard";
// import Launch from "./pages/launch/Launch";
// import Whitepaper from "./pages/whitepaper/Whitepaper";

function App() {
  return (
    <Router>
      <div className="boxed_wrapper ltr">
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About />} />
          <Route path="/token" element={<Token />} />
          <Route path="/status" element={<Status />} />
          <Route path="/sentinel" element={<Sentinel />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/research" element={<Research />} />
          <Route path="/products" element={<Products />} />
          <Route path="/mediakit" element={<MediaKit />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/list" element={<CareersList />} />
          <Route path="/careers/internship" element={<Internship />} />
          <Route path="/careers/dev-front-end" element={<DevFrontEnd />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/launch" element={<Launch />} />
          <Route path="/whitepaper" element={<Whitepaper />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
