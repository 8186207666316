import { useEffect } from "react";

const useLoadResources = (resources) => {
  useEffect(() => {
    let elements = [];

    const loadResources = () => {
      resources.forEach(({ type, src, integrity, crossorigin }) => {
        const element = document.createElement(type);
        if (type === "script") {
          element.src = src;
          element.async = true;
        } else if (type === "link") {
          element.href = src;
          element.rel = "stylesheet";
        }
        if (integrity) {
          element.integrity = integrity;
        }
        if (crossorigin) {
          element.crossOrigin = crossorigin;
        }
        document.head.appendChild(element);
        elements.push(element);
      });
    };

    loadResources();

    // return () => {
    //   elements.forEach((el) => document.head.removeChild(el));
    // };
  }, [resources]);
};


export default useLoadResources;
