// src/components/Preloader.js
import React from "react";

const Preloader = () => {
  return (
    <div className="loader-wrap">
      <div className="preloader">
        <div className="preloader-close">close</div>
        <div id="handle-preloader" className="handle-preloader">
          <div className="animation-preloader">
            <div className="spinner"></div>
            <div className="txt-loading">
              <span data-text-preloader="M" className="letters-loading">
                M
              </span>
              <span data-text-preloader="i" className="letters-loading">
                i
              </span>
              <span data-text-preloader="r" className="letters-loading">
                r
              </span>
              <span data-text-preloader="a" className="letters-loading">
                a
              </span>
              <span data-text-preloader="d" className="letters-loading">
                d
              </span>
              <span data-text-preloader="a" className="letters-loading">
                a
              </span>
              <span data-text-preloader="." className="letters-loading">
                .
              </span>
              <span data-text-preloader="A" className="letters-loading">
                A
              </span>
              <span data-text-preloader="I" className="letters-loading">
                I
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
