// src/components/CTA.js
import React from "react";

const CTA = () => {
  return (
    <section className="cta-style-two">
      <div className="auto-container">
        <div className="inner-container">
          <div className="shape" style={{ backgroundImage: "url(https://mirada.ai/assets/images/shape/shape-9.png)" }}></div>
          <div className="text-box">
            <h2>Take your creativity and productivity to the next level</h2>
            <p>With AI you can make incredible creations and get your work done faster.</p>
          </div>
          <div className="btn-box">
            <a href="https://mirada.ai/launch" target="_blank" className="theme-btn btn-one" rel="noreferrer">
              <span>Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
