// src/components/Header.js
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="main-header header-style-one">
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo logo-size-override-header">
                  <Link to="/">
                    <img src="https://mirada.ai/assets/images/logo-2.png" alt="Mirada AI" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                <div className="mobile-nav-toggler">
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li className="current">
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a target="_blank" href="https://mirada.ai/token" rel="noreferrer">
                          MIRX Token
                        </a>
                      </li>
                      <li className="dropdown">
                        <a href="https://mirada.ai/products">Products</a>
                        <ul>
                          <li>
                            <a target="_blank" href="https://mirada.ai/products#search" rel="noreferrer">
                              AI Search
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="https://mirada.ai/products#llm" rel="noreferrer">
                              LLM Chat
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="https://mirada.ai/products#image" rel="noreferrer">
                              Image Generation
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="https://mirada.ai/products#sentinel" rel="noreferrer">
                              Sentinel
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a target="_blank" href="https://mirada.ai/whitepaper" rel="noreferrer">
                          Whitepaper
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://mirada.ai/roadmap" rel="noreferrer">
                          Roadmap
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://docs.mirada.ai/" rel="noreferrer">
                          Docs
                        </a>
                      </li>
                      <li className="menu-right-in-list">
                        <a target="_blank" href="https://mirada.ai/launch" rel="noreferrer">
                          Get started
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <a href="https://mirada.ai/launch" target="_blank" className="theme-btn btn-one" rel="noreferrer">
                  <span>Get Started</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo logo-size-override-header">
                  <Link to="/">
                    <img src="https://mirada.ai/assets/images/logo-2.png" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                <nav className="main-menu clearfix">
                  <ul className="navigation clearfix">
                    <li className="current">
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/token" rel="noreferrer">
                        MIRX Token
                      </a>
                    </li>
                    <li className="dropdown">
                      <a href="https://mirada.ai/products">Products</a>
                      <ul>
                        <li>
                          <a target="_blank" href="https://mirada.ai/products#search" rel="noreferrer">
                            AI Search
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://mirada.ai/products#llm" rel="noreferrer">
                            LLM Chat
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://mirada.ai/products#image" rel="noreferrer">
                            Image Generation
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://mirada.ai/products#sentinel" rel="noreferrer">
                            Sentinel
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/whitepaper" rel="noreferrer">
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://mirada.ai/roadmap" rel="noreferrer">
                        Roadmap
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://docs.mirada.ai/" rel="noreferrer">
                        Docs
                      </a>
                    </li>
                    <li className="menu-right-in-list">
                      <a target="_blank" href="https://mirada.ai/launch" rel="noreferrer">
                        Get started
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="menu-right-content">
                <a href="https://mirada.ai/launch" target="_blank" className="theme-btn btn-one" rel="noreferrer">
                  <span>Get Started</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <i class="fas fa-times"></i>
        </div>

        <nav class="menu-box">
          <div class="nav-logo logo-size-override-header">
            <a href="https://mirada.ai">
              <img src="https://mirada.ai/assets/images/logo-3.png" alt="" title="" />
            </a>
          </div>
          <div class="menu-outer">
            <ul className="navigation clearfix">
              <li className="current">
                <Link to="/">Home</Link>
              </li>
              <li>
                <a target="_blank" href="https://mirada.ai/token" rel="noreferrer">
                  MIRX Token
                </a>
              </li>
              <li className="dropdown">
                <a href="https://mirada.ai/products">Products</a>
                <ul>
                  <li>
                    <a target="_blank" href="https://mirada.ai/products#search" rel="noreferrer">
                      AI Search
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://mirada.ai/products#llm" rel="noreferrer">
                      LLM Chat
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://mirada.ai/products#image" rel="noreferrer">
                      Image Generation
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://mirada.ai/products#sentinel" rel="noreferrer">
                      Sentinel
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a target="_blank" href="https://mirada.ai/whitepaper" rel="noreferrer">
                  Whitepaper
                </a>
              </li>
              <li>
                <a target="_blank" href="https://mirada.ai/roadmap" rel="noreferrer">
                  Roadmap
                </a>
              </li>
              <li>
                <a target="_blank" href="https://docs.mirada.ai/" rel="noreferrer">
                  Docs
                </a>
              </li>
              <li className="menu-right-in-list">
                <a target="_blank" href="https://mirada.ai/launch" rel="noreferrer">
                  Get started
                </a>
              </li>
            </ul>
          </div>
          <div class="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>
                <a href="mailto:hi@mirada.ai">hi@mirada.ai</a>
              </li>
            </ul>
          </div>
          <div class="social-links">
            <ul class="clearfix">
              <li>
                <a href="https://x.com/MiradaAI">
                  <span class="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="https://t.me/MiradaAI">
                  <span class="fab fa-telegram"></span>
                </a>
              </li>
              {/* <li>
                <a href="https://www.linkedin.com/company/mirada-ai">
                  <span class="fab fa-linkedin"></span>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
