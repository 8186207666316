/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/Banner.js
import React from "react";

const Banner = () => {
  return (
    <section className="banner-style-two p_relative" style={{ background: "#FBFAF9" }}>
      <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-7.png)" }}></div>
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-12 col-sm-12 ml-15 content-column">
            <div className="content-box">
              <h2 style={{ textAlign: "center" }}>
                Bridging <span className="ai-gradient-color">Blockchain and AI</span> for Enhanced User Autonomy
              </h2>
              <p>We’re passionate about solving problems through creative communication.</p>
              <div className="lower-box">
                <div className="partner-block">
                  <div className="img-block-container">
                    <div className="img-block">
                      <a>
                        <img className="intro-client" src="" alt="" />
                      </a>
                    </div>
                    <div className="img-block">
                      <a>
                        <img className="intro-client" src="assets/images/clients/intro-client-1.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="partner-block">
                  <div className="img-block-container">
                    <div className="img-block">
                      <a>
                        <img className="intro-client" src="assets/images/clients/intro-client-3.png" alt="" />
                      </a>
                    </div>
                    <div className="img-block">
                      <a>
                        <img className="intro-client" src="assets/images/clients/intro-client-4.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image-box">
              {/* <figure className="image image-1"><img src="assets/images/resource/dashboard-1.png" alt="" /></figure> */}
              {/* <figure className="image image-2 sentinel-logo"><img src="assets/images/resource/dashboard-2.png" alt="" /></figure> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cs-hero_social_wrap cs-primary_font cs-primary_color">
        <ul className="cs-hero_social_links hero_social_links">
          <li>
            <a target="_blank" href="https://t.me/MiradaAI" rel="noreferrer">
              <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="26px" height="126px3px">
                <path d="M5.83,23.616c12.568-5.529,28.832-12.27,31.077-13.203c5.889-2.442,7.696-1.974,6.795,3.434 c-0.647,3.887-2.514,16.756-4.002,24.766c-0.883,4.75-2.864,5.313-5.979,3.258c-1.498-0.989-9.059-5.989-10.7-7.163 c-1.498-1.07-3.564-2.357-0.973-4.892c0.922-0.903,6.966-6.674,11.675-11.166c0.617-0.59-0.158-1.559-0.87-1.086 c-6.347,4.209-15.147,10.051-16.267,10.812c-1.692,1.149-3.317,1.676-6.234,0.838c-2.204-0.633-4.357-1.388-5.195-1.676 C1.93,26.43,2.696,24.995,5.83,23.616z" />
              </svg>
            </a>
          </li>
          {/* <li>
            <a target="_blank" href="https://www.linkedin.com/company/mirada-ai" rel="noreferrer">
              <svg fill="#fff" width="20px" height="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 310 310" xmlSpace="preserve">
                <path
                  id="XMLID_802_"
                  d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
                                  C77.16,101.969,74.922,99.73,72.16,99.73z"
                />
                <path
                  id="XMLID_803_"
                  d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
                                  c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                />
                <path
                  id="XMLID_804_"
                  d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
                                  c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
                                  c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
                                  C310,145.43,300.549,94.761,230.454,94.761z"
                />
              </svg>
            </a>
          </li> */}
          <li>
            <a target="_blank" href="https://x.com/MiradaAI" rel="noreferrer">
              <svg width="26px" height="26px" viewBox="0 0 13 11" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.4651 2.95396C11.4731 3.065 11.4731 3.17606 11.4731 3.2871C11.4731 6.67383 8.89535 10.5761 4.18402 10.5761C2.73255 10.5761 1.38421 10.1557 0.25 9.42608C0.456226 9.44986 0.654494 9.4578 0.868655 9.4578C2.06629 9.4578 3.16879 9.0533 4.04918 8.36326C2.92291 8.33946 1.97906 7.60183 1.65386 6.5866C1.81251 6.61038 1.97112 6.62624 2.1377 6.62624C2.36771 6.62624 2.59774 6.59451 2.81188 6.53901C1.63802 6.30105 0.757595 5.26996 0.757595 4.02472V3.99301C1.09864 4.18336 1.49524 4.30233 1.91558 4.31818C1.22554 3.85814 0.773464 3.07294 0.773464 2.1846C0.773464 1.70872 0.900344 1.27249 1.12244 0.891774C2.38355 2.44635 4.27919 3.46156 6.40481 3.57262C6.36516 3.38226 6.34136 3.184 6.34136 2.9857C6.34136 1.57388 7.4835 0.423828 8.90323 0.423828C9.64086 0.423828 10.3071 0.733156 10.7751 1.23284C11.354 1.1218 11.9093 0.907643 12.401 0.614185C12.2106 1.20906 11.8061 1.70875 11.2748 2.02598C11.7903 1.97049 12.29 1.82769 12.75 1.62942C12.4011 2.13702 11.9648 2.5891 11.4651 2.95396V2.95396Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Banner;
