// src/components/Features.js
import React from "react";

const Features = () => {
  return (
    <section className="feature-style-two pb_100">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_two">
              <div className="image-box pl_45 pt_40 pr_100 mr_40 pb_65">
                <figure className="image image-1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                  <img src="assets/images/resource/dashboard-3.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content_block_three">
              <div className="content-box ml_40">
                <div className="sec-title mb_25">
                  <span className="sub-title">Partnership</span>
                  <h2>Mirada AI API Integrations</h2>
                </div>
                <div className="text-box">
                  <p>Quickly and easily integrate our AI solutions into third-party product and services via our developer-friendly API.</p>
                  <ul className="list-style-one mb_40 clearfix">
                    <li>You can use AI for almost anything you need. To act as an AI assistant, offer an alternative to traditional search, aid your research & development, and more.</li>
                    <li>Transparent, trackable pricing dependent on usage.</li>
                  </ul>
                  <a href="https://mirada.ai/contact" className="theme-btn btn-one">
                    <span>Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
