import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/font-awesome-all.css';
import './assets/css/flaticon.css';
import './assets/css/owl.css';
import './assets/css/bootstrap.css';
import './assets/css/jquery.fancybox.min.css';
import './assets/css/animate.css';
import './assets/css/aos.css';
import './assets/css/nice-select.css';
import './assets/css/elpath.css';
import './assets/css/color.css';
import './assets/css/style.css';
import './assets/css/module-css/banner.css';
import './assets/css/module-css/clients.css';
import './assets/css/module-css/chooseus.css';
import './assets/css/module-css/feature.css';
import './assets/css/module-css/about.css';
import './assets/css/module-css/service.css';
import './assets/css/module-css/pricing.css';
import './assets/css/module-css/faq.css';
import './assets/css/module-css/news.css';
import './assets/css/module-css/cta.css';
import './assets/css/module-css/funfact.css';

import "./assets/css/module-css/team.css";
import "./assets/css/module-css/page-title.css";
import "./assets/css/module-css/testimonial.css";
import "./assets/css/module-css/launch.css";

import './assets/css/responsive.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
